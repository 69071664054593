import React from "react"
import { graphql } from "gatsby"
import LoadMore from "../components/LoadMore"
import DirectoryMenu from "../components/DirectoryMenu"
import Seo from "../components/Seo"
import Heading from "../components/Heading"
import ListingCard from "../components/ListingCard"

export default function NewsIndex({ data }) {
  const page = data && data.page
  const posts = data && data.posts

  const today = new Date().toISOString()
  const future = posts.nodes.filter(futureEvent => futureEvent.end > today)
  const past = posts.nodes.filter(pastEvent => pastEvent.end < today)

  past.sort((a, b) => new Date(b.end) - new Date(a.end))
  future.sort((a, b) => new Date(a.end) - new Date(b.end))

  return (
    <>
      <Seo
        metaTitle={page.seo?.metaTitle || page.title}
        shareTitle={page.seo?.shareTitle || page.title}
        metaDesc={page.seo?.metaDesc}
        shareDesc={page.seo?.shareDesc}
      />
      <div className="flex flex-col justify-stretch w-full">
        <section className="wrapper order-last lg:order-first">
          <div className="py-4">
            <Heading text="Directory" />
            <DirectoryMenu cols={8} />
          </div>
        </section>
        <section className="wrapper my-4">
          <Heading text="Upcoming events" />
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            {future.map(event => {
              return <ListingCard post={event} />
            })}
          </div>
        </section>
        <section className="wrapper my-4">
          <Heading text="Events archive" />
          <LoadMore posts={past} />
        </section>
      </div>
    </>
  )
}

export const query = graphql`
  query eventsIndexQuery {
    page: sanityEventsIndex {
      _id
      title
      seo {
        ...seoFields
      }
    }
    posts: allSanityEvents(sort: { fields: end, order: ASC }) {
      nodes {
        title
        _id
        _type
        slug {
          current
        }
        start
        end
        content {
          subHeading
          featureImage {
            asset {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`
